import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import { Container, Modal, Box, TextField, Typography, Button } from '@mui/material';
import axios from 'axios';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/themes/gray.min.css';
import Autosuggest from 'react-autosuggest';

const AdminPage = () => {
  const [markers, setMarkers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newMarker, setNewMarker] = useState({ lat: '', lng: '', text: '', image: '' });
  const [editorContent, setEditorContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [searchResult, setSearchResult] = useState(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('/markers', {
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      setMarkers(response.data);
    }).catch(error => {
      console.error(error);
    });
  }, []);

  const handleMapClick = (e) => {
    setNewMarker({ lat: e.latlng.lat, lng: e.latlng.lng, text: '', image: '' });
    setEditorContent('');
    setModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMarker((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveMarker = () => {
    const token = localStorage.getItem('token');
    const newMarkerData = { ...newMarker, text: editorContent };
    axios.post('/markers', newMarkerData, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      setMarkers([...markers, newMarkerData]);
      setModalOpen(false);
    }).catch(error => {
      console.error(error);
    });
  };

  const customIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const froalaConfig = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    theme: 'gray',
    toolbarButtons: [
      'bold', 'italic', 'underline', 'paragraphFormat', 'align', 
      'formatOL', 'formatUL', 'quote', 'insertLink', 'insertImage', 
      'insertVideo', 'undo', 'redo'
    ],
    pluginsEnabled: ['align', 'image', 'video'],
    imageUploadURL: '/upload_image',
    videoUploadURL: '/upload_video',
    events: {
      'image.beforeUpload': function (files) {
        const token = localStorage.getItem('token');
        const data = new FormData();
        data.append('file', files[0]);

        axios.post('/upload_image', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.image.insert(response.data.link, null, null, this.image.get());
        }).catch(error => {
          console.error(error);
        });

        return false;
      },
      'video.beforeUpload': function (files) {
        const token = localStorage.getItem('token');
        const data = new FormData();
        data.append('file', files[0]);

        axios.post('/upload_video', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.video.insert(response.data.link, null, null, this.video.get());
        }).catch(error => {
          console.error(error);
        });

        return false;
      }
    },
    imageManagerLoadURL: '/load_images',
    requestHeaders: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  };

  const getSuggestions = (value) => {
    return new Promise((resolve) => {
      axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${value}&limit=10`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error(error);
          resolve([]);
        });
    });
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    getSuggestions(value).then(suggestions => setSuggestions(suggestions));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    setSearchResult({ lat: suggestion.lat, lng: suggestion.lon });
    setSearchQuery(suggestion.display_name);
    if (map) {
      map.setView([suggestion.lat, suggestion.lon], 16); // Zooming to level 16
    }
  };

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.display_name}
    </div>
  );

  const inputProps = {
    placeholder: "Search Address",
    value: searchQuery,
    onChange: (e, { newValue }) => setSearchQuery(newValue)
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Admin Map</Typography>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={suggestion => suggestion.display_name}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      <MapContainer
        center={[58.4646, 7.9976]}
        zoom={13}
        style={{ height: '500px', width: '100%' }}
        whenCreated={setMap}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, index) => (
          <Marker key={index} position={[marker.lat, marker.lng]} icon={customIcon}>
            <Popup>{marker.text}</Popup>
          </Marker>
        ))}
        {searchResult && (
          <Marker position={[searchResult.lat, searchResult.lng]} icon={customIcon}>
            <Popup>Search Result</Popup>
          </Marker>
        )}
        <MapClickHandler onClick={handleMapClick} />
        <UserLocationMarker setUserPosition={() => {}} />
      </MapContainer>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6">Add Marker</Typography>
          <TextField
            fullWidth
            name="image"
            label="Image URL"
            value={newMarker.image}
            onChange={handleInputChange}
            margin="normal"
          />
          <Typography variant="body1">Description</Typography>
          <Box sx={{ border: '1px solid #ccc', padding: '5px', marginTop: '10px' }}>
            <FroalaEditor
              tag='textarea'
              config={froalaConfig}
              model={editorContent}
              onModelChange={setEditorContent}
            />
          </Box>
          <Button variant="contained" color="primary" onClick={handleSaveMarker} style={{ marginTop: '10px' }}>
            Save
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const MapClickHandler = ({ onClick }) => {
  useMapEvents({
    click: onClick,
  });
  return null;
};

const UserLocationMarker = ({ setUserPosition }) => {
  const [userPosition, setUserPositionState] = useState(null);

  const map = useMapEvents({
    locationfound(e) {
      setUserPositionState(e.latlng);
      setUserPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
    locationerror(e) {
      console.error('Location error:', e.message);
    },
  });

  useEffect(() => {
    map.locate({ setView: true, maxZoom: 16 });
  }, [map]);

  const customIcon = L.icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  return userPosition === null ? null : (
    <Marker position={userPosition} icon={customIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

export default AdminPage;

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import { Container, Typography, Modal, Box, Button, IconButton, useMediaQuery, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/themes/gray.min.css';

const MapComponent = () => {
  const [markers, setMarkers] = useState([]);
  const [userPosition, setUserPosition] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [processedHtml, setProcessedHtml] = useState('');
  const [nearbyMarkers, setNearbyMarkers] = useState(JSON.parse(localStorage.getItem('nearbyMarkers')) || []);
  const [readMarkers, setReadMarkers] = useState(JSON.parse(localStorage.getItem('readMarkers')) || []);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const token = localStorage.getItem('token');

  useEffect(() => {
    axios.get('/api/markers', {
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      setMarkers(response.data);
    }).catch(error => {
      console.error(error);
    });

    // Sync local nearby markers with backend
    nearbyMarkers.forEach(marker => {
      axios.post('/api/save_nearby_marker', { marker_id: marker.id }, {
        headers: { Authorization: `Bearer ${token}` }
      }).catch(error => {
        console.error('Error saving nearby marker to backend:', error);
      });
    });
  }, [token]);

  const checkInRange = (marker) => {
    if (!userPosition) return false;
    const distance = L.latLng(marker.lat, marker.lng).distanceTo(userPosition);
    console.log(`Avstand til ${marker.lat}, ${marker.lng}: ${distance} meter`);
    return distance < 25; // 25 meter radius for å besøke punktet
  };

  const handleMarkerClick = (marker) => {
    console.log('Markør klikket:', marker);
    if (checkInRange(marker)) {
      setSelectedMarker(marker);
      setModalOpen(true);
      processHtmlContent(marker.text);
      saveNearbyMarker(marker);
      markAsRead(marker.id);
    } else {
      setSnackbarOpen(true);
    }
  };

  const saveNearbyMarker = (marker) => {
    const updatedNearbyMarkers = [...nearbyMarkers, marker];
    setNearbyMarkers(updatedNearbyMarkers);
    localStorage.setItem('nearbyMarkers', JSON.stringify(updatedNearbyMarkers));

    axios.post('/api/save_nearby_marker', { marker_id: marker.id }, {
      headers: { Authorization: `Bearer ${token}` }
    }).catch(error => {
      console.error('Feil ved lagring av nærliggende markør til backend:', error);
    });
  };

  const markAsRead = (markerId) => {
    if (!readMarkers.includes(markerId)) {
      const updatedReadMarkers = [...readMarkers, markerId];
      setReadMarkers(updatedReadMarkers);
      localStorage.setItem('readMarkers', JSON.stringify(updatedReadMarkers));
    }
  };

  const processHtmlContent = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const images = doc.getElementsByTagName('img');
    for (let img of images) {
      const src = img.getAttribute('src');
      if (src && src.startsWith('/uploads/')) {
        try {
          const response = await axios.get(src, {
            headers: { Authorization: `Bearer ${token}` },
            responseType: 'blob'
          });
          const imageUrl = URL.createObjectURL(response.data);
          img.setAttribute('src', imageUrl);
        } catch (error) {
          console.error('Feil ved henting av bilde:', error);
        }
      }
    }
    setProcessedHtml(doc.body.innerHTML);
  };

  const unreadIcon = L.icon({
    iconUrl: 'https://img.icons8.com/ios-filled/50/FA5252/visit.png', // Bruk en annen ikon-URL for uleste markører
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const readIcon = L.icon({
    iconUrl: 'https://img.icons8.com/ios-filled/50/40C057/visit.png', // Bruk en annen ikon-URL for leste markører
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  return (
    <Container>
      <Typography variant="h4" gutterBottom>NaturSti</Typography>
      <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: isMobile ? '300px' : '500px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> bidragsytere'
        />
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={[marker.lat, marker.lng]}
            icon={readMarkers.includes(marker.id) ? readIcon : unreadIcon}
          >
            <Popup>
              <Button onClick={() => handleMarkerClick(marker)}>Vis Info</Button>
            </Popup>
          </Marker>
        ))}
        <UserLocationMarker setUserPosition={setUserPosition} />
      </MapContainer>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          position: 'relative',
          width: isMobile ? '100%' : 400,
          maxHeight: isMobile ? '100%' : '90%',
          height: isMobile ? '100%' : 'auto',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: isMobile ? 0 : 1,
        }}>
          <IconButton
            onClick={() => setModalOpen(false)}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedMarker && (
            <>
              <Typography variant="h6" gutterBottom>Post informasjon</Typography>
              <FroalaEditorView model={processedHtml} />
              <Button
                onClick={() => setModalOpen(false)}
                sx={{
                  marginTop: 2,
                  width: '100%',
                }}
                variant="contained"
                color="primary"
              >
                Lukk
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="warning" sx={{ width: '100%' }}>
          Du må være innenfor 25 meter av punktet for å se mer informasjon.
        </Alert>
      </Snackbar>
    </Container>
  );
};

const UserLocationMarker = ({ setUserPosition }) => {
  const [userPosition, setUserPositionState] = useState(null);

  const map = useMapEvents({
    locationfound(e) {
      setUserPositionState(e.latlng);
      setUserPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  useEffect(() => {
    map.locate({ setView: true, maxZoom: 16 });
  }, [map]);

  const customIcon = L.icon({
    iconUrl: 'https://img.icons8.com/material-sharp/24/000000/near-me.png',
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  return userPosition === null ? null : (
    <Marker position={userPosition} icon={customIcon}>
      <Popup>Du er her</Popup>
    </Marker>
  );
};

export default MapComponent;

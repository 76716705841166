// src/App.js
import React, { useState, useEffect } from 'react';
import Auth from './Auth';
import MapComponent from './MapComponent';
import Admin from './Admin';
import AdminPage from './AdminPage'; // Importer AdminPage
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Container } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

function App() {
  const [token, setToken] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      const decoded = jwtDecode(savedToken);
      setToken(savedToken);
      setIsAdmin(decoded.sub.is_admin);
      console.log('Admin status from localStorage in App:', decoded.sub.is_admin); // Legg til konsollutskrift
    }
  }, []);

  if (!token) {
    return <Auth setToken={setToken} setIsAdmin={setIsAdmin} />;
  }

  return (
    <Router>
      <div className="App">
        <AppBar position="static">
          <Toolbar>
            <Button color="inherit" component={Link} to="/">Kart</Button>
            {isAdmin && (
              <>
                <Button color="inherit" component={Link} to="/admin">Admin</Button>
                <Button color="inherit" component={Link} to="/admin-page">Admin Page</Button>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Container>
          <Routes>
            <Route path="/admin" element={isAdmin ? <Admin token={token} /> : <p>Access denied</p>} />
            <Route path="/admin-page" element={isAdmin ? <AdminPage token={token} /> : <p>Access denied</p>} />
            <Route path="/" element={<MapComponent token={token} isAdmin={isAdmin} />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;

// src/Auth.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Container, Box, FormControlLabel, Checkbox } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

const Auth = ({ setToken, setIsAdmin }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setToken(token);
      setIsAdmin(decoded.sub.is_admin);
      console.log('Admin status from localStorage:', decoded.sub.is_admin); // Legg til konsollutskrift
    }
  }, [setToken, setIsAdmin]);

  const handleAuth = () => {
    const url = `/api/${isRegister ? 'register' : 'login'}`;
    axios.post(url, { username, password, is_admin: admin })
      .then(response => {
        if (!isRegister) {
          const { access_token } = response.data;
          const decoded = jwtDecode(access_token);
          localStorage.setItem('token', access_token);
          setToken(access_token);
          setIsAdmin(decoded.sub.is_admin);
          console.log('Admin status after login:', decoded.sub.is_admin); // Legg til konsollutskrift
        }
      }).catch(error => {
        console.error(error);
      });
  };

  return (
    <Container maxWidth="sm">
      <Box my={4} textAlign="center">
        <Typography variant="h4">{isRegister ? 'Register' : 'Login'}</Typography>
        <TextField
          fullWidth
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
        {isRegister && (
          <FormControlLabel
            control={
              <Checkbox
                checked={admin}
                onChange={(e) => setAdmin(e.target.checked)}
                name="admin"
                color="primary"
              />
            }
            label="Admin"
          />
        )}
        <Button variant="contained" color="primary" onClick={handleAuth}>
          {isRegister ? 'Register' : 'Login'}
        </Button>
        <Button onClick={() => setIsRegister(!isRegister)}>
          {isRegister ? 'Already have an account? Login' : 'No account? Register'}
        </Button>
      </Box>
    </Container>
  );
};

export default Auth;

// src/Admin.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Container, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Admin = ({ token }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get('/api/users', {
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      setUsers(response.data);
    }).catch(error => {
      console.error(error);
    });
  }, [token]);

  const handleDeleteUser = (userId) => {
    axios.delete('/api/users', {
      headers: { Authorization: `Bearer ${token}` },
      data: { id: userId }
    }).then(response => {
      setUsers(users.filter(user => user.id !== userId));
    }).catch(error => {
      console.error(error);
    });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Admin Panel</Typography>
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <ListItemText primary={user.username} secondary={user.is_admin ? "Admin" : "User"} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteUser(user.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Admin;
